import { Co2FootprintVO } from 'vo';
import { VenueName } from './constants/venues';

export type MapPoint = {
  lat: number;
  lng: number;
};

export enum EventSpaceMeasureUnit {
  m2 = 'm2',
}

export enum FoodSource {
  AllFoodIsLocallySorced = 'AllFoodIsLocallySorced',
  SomeFoodIsLocallySorced = 'SomeFoodIsLocallySorced',
  NoFoodIsLocallySourced = 'NoFoodIsLocallySourced',
}

export enum StandType {
  BASIC = 'basic',
  REGULAR = 'regular',
  CREATIVE = 'creative',
}

export interface CalculatorFormValues {
  welcome?: string[];

  eventName?: string;
  email?: string;

  eventVenue?: VenueName;
  eventVenueSize?: number;
  eventDurationHours?: number;
  eventDurationDates?: [Date, Date]; // [beginingDate, endDate]
  eventSpaceMeasureUnit?: EventSpaceMeasureUnit;

  livingLocallyPeopleCount?: number;
  travelingWithinRegionPeopleCount?: number;
  travelingWithinCountryPeopleCount?: number;
  travelingFromAnotherCountryPeopleCount?: number;

  //Hospitality
  stayingInHotelsPeopleCount?: number;
  hotelNightsPerAttendeeCount?: number;
  knowTheApproximateNumberOfPeople?: boolean;
  percentageOfPeopleInTwoStarHotel?: number;
  percentageOfPeopleInThreeStarHotel?: number;
  percentageOfPeopleInFourStarHotel?: number;
  percentageOfPeopleInFiveStarHotel?: number;

  isCateringDetailed?: boolean;
  // if isCateringDetailed true
  foodSource?: FoodSource;
  breakfastsNumber?: number;
  fishOrMeatLunchesNumber?: number;
  vegetarianLunchesNumber?: number;
  meatDinnersNumber?: number;
  fishOrPoultryDinnersNumber?: number;
  vegetarianDinnersNumber?: number;
  // if isCateringDetailed false
  lightMealNumber?: number;
  seatedMealsNumber?: number;

  isMerchandiseDetailed?: boolean;
  // if isMerchandiseDetailed true
  tShirtsCount?: number;
  brandedBagsCount?: number;
  usedPlasticKg?: number;
  usedPaperKg?: number;
  usedWoodKg?: number;

  // if isMerchandiseDetailed false
  hasExhibitors?: boolean;
  exhibitorsNumber?: number;
  promotionalBagsNumber?: number;
  standTypes?: StandType[];
}

export enum StayingPlace {
  HOTEL_2_STARS = 'HOTEL_2_STARS',
  HOTEL_3_STARS = 'HOTEL_3_STARS',
  HOTEL_4_STARS = 'HOTEL_4_STARS',
  HOTEL_5_STARS = 'HOTEL_5_STARS',
  OTHER_HOTELS = 'OTHER_HOTELS',
}

export enum StepName {
  WELCOME = 'WELCOME',
  EVENT_SUMMARY = 'EVENT_SUMMARY',
  TRAVEL_AND_ACCOMMODATION = 'TRAVEL_AND_ACCOMMODATION',
  HOSPITALITY = 'HOSPITALITY',
  CATERING = 'CATERING',
  MERCHANDISE = 'MERCHANDISE',
  DURATION_AND_VENUE_INFO = 'PLACE_TO_STAY',
}

export type Co2FootprintsVOs = {
  travelAndAccommodation: Co2FootprintVO;
  hospitality: Co2FootprintVO;
  catering: Co2FootprintVO;
  merchandise: Co2FootprintVO;
  eventDurationAndInfo: Co2FootprintVO;
};
