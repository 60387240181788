const kgToTon = (kg: number) => kg / 1000;
const kgToGram = (kg: number) => kg * 1000;

// VO is Value Object, read this article https://en.wikipedia.org/wiki/Value_object
export class Co2FootprintVO {
  static create(value: number, unit: 'kg') {
    return new Co2FootprintVO(value, unit);
  }

  static sum(items: Array<Co2FootprintVO>) {
    const newValue = items.reduce((acc, currentVO) => {
      return acc + currentVO.kg;
    }, 0);

    return new Co2FootprintVO(newValue, 'kg');
  }

  readonly value: number;
  readonly unit: 'kg';

  constructor(value: number, unit: 'kg') {
    this.value = value;
    this.unit = unit;
  }

  get gram() {
    return kgToGram(this.kg);
  }

  get kg() {
    return this.value;
  }

  get ton() {
    return kgToTon(this.kg);
  }

  get formattedGram() {
    return Number(this.gram.toFixed(2));
  }

  get formattedKg() {
    return Number(this.kg.toFixed(2));
  }

  get formattedTon() {
    return Number(this.ton.toFixed(2));
  }

  get formattedValue() {
    const { kg } = this;
    if (kg >= 1000) {
      return this.formattedTon;
    }
    if (kg < 1) {
      return this.formattedGram;
    }
    return this.formattedKg;
  }

  get stringifiedGram() {
    return `${this.formattedGram} g`;
  }

  get stringifiedTon() {
    return `${this.formattedTon} t`;
  }

  get stringifiedKg() {
    return `${this.formattedKg} kg`;
  }

  get stringifiedValue() {
    const { kg } = this;
    if (kg >= 1000) {
      return this.stringifiedTon;
    }
    if (kg < 1) {
      return this.stringifiedGram;
    }
    return this.stringifiedKg;
  }

  toString() {
    return this.stringifiedValue;
  }
}
