import { StayingPlace } from 'common/types';
import { Co2FootprintVO } from 'vo';

/**
 * These values calculated by hand using this site https://www.hotelfootprints.org/
 * (Select France, and choose hotels in "select options" - results will be below)
 *
 * Multipliers taken from the documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */
const HOTEL_NIGHT_AVERAGE_EMISSION_COEFFICIENT = 33.38;

const EMISSION_COEFFICIENT_BY_STAYING_PLACE: Record<StayingPlace, number> = {
  [StayingPlace.HOTEL_2_STARS]: 0.33,
  [StayingPlace.HOTEL_3_STARS]: 0.5,
  [StayingPlace.HOTEL_4_STARS]: 1.14,
  [StayingPlace.HOTEL_5_STARS]: 1.86,
  [StayingPlace.OTHER_HOTELS]: HOTEL_NIGHT_AVERAGE_EMISSION_COEFFICIENT,
};

type Props = {
  stayingInHotelsPeopleCount?: number;
  hotelNightsPerAttendeeCount?: number;
  percentageOfPeopleInTwoStarHotel?: number;
  percentageOfPeopleInThreeStarHotel?: number;
  percentageOfPeopleInFourStarHotel?: number;
  percentageOfPeopleInFiveStarHotel?: number;
};

const percentagePeopleToNumber = (percentage: number, peopleCount: number) =>
  peopleCount === 0 ? 0 : Math.round((peopleCount / 100) * percentage);

const getEmissionByStayingPlace = (peopleCount: number, nightCount: number, hotel: StayingPlace) => {
  return peopleCount * nightCount * EMISSION_COEFFICIENT_BY_STAYING_PLACE[hotel];
};

export default (props: Props) => {
  const {
    stayingInHotelsPeopleCount = 0,
    hotelNightsPerAttendeeCount = 0,
    percentageOfPeopleInTwoStarHotel = 0,
    percentageOfPeopleInFiveStarHotel = 0,
    percentageOfPeopleInFourStarHotel = 0,
    percentageOfPeopleInThreeStarHotel = 0,
  } = props;

  if (stayingInHotelsPeopleCount === 0) {
    const co2FootprintValue = hotelNightsPerAttendeeCount * HOTEL_NIGHT_AVERAGE_EMISSION_COEFFICIENT;
    return Co2FootprintVO.create(co2FootprintValue, 'kg');
  }

  const numberOfPeopleLiveInTwoStarHotel = percentagePeopleToNumber(
    percentageOfPeopleInTwoStarHotel,
    stayingInHotelsPeopleCount,
  );
  const numberOfPeopleLiveInThreeStarHotel = percentagePeopleToNumber(
    percentageOfPeopleInThreeStarHotel,
    stayingInHotelsPeopleCount,
  );
  const numberOfPeopleLiveInFourStarHotel = percentagePeopleToNumber(
    percentageOfPeopleInFourStarHotel,
    stayingInHotelsPeopleCount,
  );
  const numberOfPeopleLiveInFiveStarHotel = percentagePeopleToNumber(
    percentageOfPeopleInFiveStarHotel,
    stayingInHotelsPeopleCount,
  );

  const sumPeopleLiveInHotelsWithStarts =
    numberOfPeopleLiveInTwoStarHotel +
    numberOfPeopleLiveInThreeStarHotel +
    numberOfPeopleLiveInFourStarHotel +
    numberOfPeopleLiveInFiveStarHotel;

  const numberOfPeopleLiveInAnyHotel = stayingInHotelsPeopleCount - sumPeopleLiveInHotelsWithStarts;

  const co2FootprintValueOfPeopleLiveInTwoStarHotel = getEmissionByStayingPlace(
    numberOfPeopleLiveInTwoStarHotel,
    hotelNightsPerAttendeeCount,
    StayingPlace.HOTEL_2_STARS,
  );

  const co2FootprintValueOfPeopleLiveInThreeStarHotel = getEmissionByStayingPlace(
    numberOfPeopleLiveInThreeStarHotel,
    hotelNightsPerAttendeeCount,
    StayingPlace.HOTEL_3_STARS,
  );

  const co2FootprintValueOfPeopleLiveInFourStarHotel = getEmissionByStayingPlace(
    numberOfPeopleLiveInFourStarHotel,
    hotelNightsPerAttendeeCount,
    StayingPlace.HOTEL_4_STARS,
  );

  const co2FootprintValueOfPeopleLiveInFiveStarHotel = getEmissionByStayingPlace(
    numberOfPeopleLiveInFiveStarHotel,
    hotelNightsPerAttendeeCount,
    StayingPlace.HOTEL_5_STARS,
  );

  const co2FootprintValueOfPeopleLiveInAnyHotel = getEmissionByStayingPlace(
    numberOfPeopleLiveInAnyHotel,
    hotelNightsPerAttendeeCount,
    StayingPlace.OTHER_HOTELS,
  );

  const co2FootprintValue =
    co2FootprintValueOfPeopleLiveInAnyHotel +
    co2FootprintValueOfPeopleLiveInTwoStarHotel +
    co2FootprintValueOfPeopleLiveInThreeStarHotel +
    co2FootprintValueOfPeopleLiveInFourStarHotel +
    co2FootprintValueOfPeopleLiveInFiveStarHotel;

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};
