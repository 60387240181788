import { Field, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues } from 'common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { combineValidators } from 'validators/combineValidators';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { DoubleCalendar, Input, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

const translationPrefix = 'eventsCommon.pages.durationAndVenue';

export const DurationAndVenuePage: FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle>{t(translationPrefix + '.title')}</StepTitle>
      <StepDescription>{t(translationPrefix + '.subTitle')}</StepDescription>

      <FormWrap>
        <Field name="eventDurationDates" validate={validators.isRequired}>
          {({ input }: FieldRenderProps<any>) => (
            <StyledDoubleCalendar
              firstLabel={t(translationPrefix + '.form.field.eventDurationDates.beginingDate.label')}
              secondLabel={t(translationPrefix + '.form.field.eventDurationDates.endDate.label')}
              {...input}
              defaultValue={input.value}
            />
          )}
        </Field>
        <Field
          name="eventDurationHours"
          type="number"
          pattern="[0-9]*"
          parse={formParsers.toNumber}
          format={formFormatters.numberToString}
          validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
          {field => (
            <StyledInput
              label={t(translationPrefix + '.form.field.eventDurationHours.label')}
              placeholder="0"
              {...field}
            />
          )}
        </Field>
        <InputTip>{t(translationPrefix + '.form.field.eventDurationHours.tip')}</InputTip>
        <Field
          name="eventVenueSize"
          type="text"
          parse={formParsers.toNumber}
          format={formFormatters.numberToString}
          validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
          {field => (
            <StyledInput label={t(translationPrefix + '.form.field.eventSpaceSize.label')} placeholder="0" {...field} />
          )}
        </Field>
      </FormWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDoubleCalendar = styled(DoubleCalendar)`
  margin-bottom: 20px;
  z-index: 1;
  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const StyledInput = styled(Input)`
  white-space: nowrap;
  & > div {
    margin-bottom: 0;
  }
`;

const InputTip = styled.p`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-top: 8px;
  margin-bottom: 24px;
  color: var(--color9);

  @media ${DEVICE.laptop} {
    margin-top: 12px;
  }
`;
