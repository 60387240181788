import { TopContainer, TipsToReduce, ReceiveResult, ActionButtons } from './components';
import styled from 'styled-components/macro';
import { useEffect } from 'react';
import { Co2FootprintVO } from 'vo';
import { CalculatorFormValues, Co2FootprintsVOs } from 'common/types';
import { useCo2FootprintPerAttendeVOs } from 'hooks/useCo2FootprintPerAttendeVOs';
import { DEVICE } from '@net0-calc/common/assets/styles';

type Props = {
  formValues: CalculatorFormValues;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  answerId?: string;
};

export const Result = ({ formValues, co2FootprintSumVO, co2FootprintsVOs, answerId = '' }: Props) => {
  const co2FootprintPerAttendeVOs = useCo2FootprintPerAttendeVOs(formValues, co2FootprintsVOs);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Root>
      <TopContainer
        eventName={formValues.eventName || 'Unknown'}
        co2FootprintSumVO={co2FootprintSumVO}
        co2FootprintsVOs={co2FootprintsVOs}
        co2FootprintPerAttendeVOs={co2FootprintPerAttendeVOs}
      />
      <TipsToReduce answerId={answerId} />
      <DesktopActionsWrapper>
        <ActionButtons
          formValues={formValues}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
          answerId={answerId}
        />
      </DesktopActionsWrapper>
      <ReceiveResult answerId={answerId} />
      <MobileActionsWrapper>
        <ActionButtons
          formValues={formValues}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
          answerId={answerId}
        />
      </MobileActionsWrapper>
      <CertificateImageContainer>
        <img src="https://net0.s3.amazonaws.com/net0-cert.png" alt="Certificate" />
      </CertificateImageContainer>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 25px;

  @media ${DEVICE.laptop} {
    padding-bottom: 50px;
  }
`;

const CertificateImageContainer = styled.div`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  padding: 20px 0 50px;

  & > img {
    width: 62px;
    height: 62px;
  }

  @media ${DEVICE.laptop} {
    right: 0;
    bottom: 50px;
    padding: 0;
    padding-right: 80px;

    & > img {
      width: 100px;
      height: 100px;
    }
  }
`;

const DesktopActionsWrapper = styled.div`
  display: none;

  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const MobileActionsWrapper = styled.div`
  display: block;
  & > div {
    margin-bottom: 25px;
  }

  @media ${DEVICE.laptop} {
    display: none;
  }
`;
