import { Field } from 'react-final-form';
import styled, { css } from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues, StandType } from 'common/types';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { combineValidators } from 'validators/combineValidators';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { BasicStandIcon, CheckIcon, CreativeStandIcon, RegularStandIcon } from '@net0-calc/common/assets/svg';
import { DetailedFormSwitch } from '@net0-calc/common/components';
import { TimeUtil } from '@net0-calc/common/utils';
import {
  Hints,
  Input,
  RadioButton,
  CardRadioButton,
  StepDescription,
  StepTitle,
} from '@net0-calc/common/components/ui';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { WizardPageProps } from '@net0-calc/common/types';

const LABEL_CSS = css`
  ${TYPOGRAPHY.bodyRegular16}
`;

const detailedFieldNames = ['tShirtsCount', 'brandedBagsCount', 'usedPlasticKg', 'usedPaperKg', 'usedWoodKg'];

const ICON_WRAPPER_CSS = css<{ $isChecked?: boolean }>`
  & svg path {
    width: 60px;
    height: 60px;
    stroke: none;
    transition: fill 1s;
  }
  ${({ $isChecked }) =>
    $isChecked
      ? css`
          & svg path {
            fill: var(--color5);
          }
        `
      : css`
          & svg {
            & path {
              fill: var(--color1);
            }
          }
        `}
`;

export const MerchandisePage: FC<WizardPageProps<CalculatorFormValues>> = ({ values, form }) => {
  const { t } = useTranslation();

  const detailedFields = useMemo(() => {
    return detailedFieldNames.map(name => {
      return { label: t(`eventsCommon.pages.merchandise.form.field.${name}.label`), name };
    });
  }, []);

  const options = [
    {
      value: StandType.BASIC,
      title: t(`eventsCommon.pages.merchandise.form.field.standType.options.basic`),
      icon: <BasicStandIcon />,
    },
    {
      value: StandType.REGULAR,
      title: t(`eventsCommon.pages.merchandise.form.field.standType.options.regular`),
      icon: <RegularStandIcon />,
    },
    {
      value: StandType.CREATIVE,
      title: t(`eventsCommon.pages.merchandise.form.field.standType.options.creative`),
      icon: <CreativeStandIcon />,
    },
  ];

  const isDetailedForm = values?.isMerchandiseDetailed;
  const hasExhibitors = values?.hasExhibitors;

  useEffect(() => {
    const eventDurationDates = values?.eventDurationDates || [new Date(), new Date()];
    const eventBeginingDate = eventDurationDates[0];
    const mothsDiffBetweenNowAndEventBeginingDate = TimeUtil.getDiffFromNow(eventBeginingDate, 'months');
    const isMerchandiseDetailed = mothsDiffBetweenNowAndEventBeginingDate < 6;
    if (form) {
      form.change('isMerchandiseDetailed', isMerchandiseDetailed);
    }
  }, []);

  const hint = isDetailedForm
    ? t('eventsCommon.pages.merchandise.hint.advanced')
    : t('eventsCommon.pages.merchandise.hint.simple');

  return (
    <Root>
      <DetailedFormSwitch isDetailedForm={!!isDetailedForm} name="isMerchandiseDetailed" />
      <StepTitle>{t('eventsCommon.pages.merchandise.title')}</StepTitle>
      <StepDescription>{t('eventsCommon.pages.merchandise.subTitle')}</StepDescription>

      {isDetailedForm ? (
        <FormWrap>
          {detailedFields.map(({ name, label }) => (
            <Field
              key={name}
              name={name}
              type="number"
              pattern="[0-9]*"
              parse={formParsers.toNumber}
              format={formFormatters.numberToString}
              validate={combineValidators(validators.isNegativeNumber, validators.isNumber)}>
              {field => <StyledInput label={label} placeholder="0" {...field} />}
            </Field>
          ))}
        </FormWrap>
      ) : (
        <FormWrap>
          <Field
            name="promotionalBagsNumber"
            type="number"
            pattern="[0-9]*"
            parse={formParsers.toNumber}
            format={formFormatters.numberToString}
            validate={combineValidators(validators.isNegativeNumber, validators.isNumber)}>
            {field => (
              <StyledInput
                label={t('eventsCommon.pages.merchandise.form.field.promotionalBagsNumber.label')}
                placeholder="0"
                {...field}
              />
            )}
          </Field>
          <Field name="hasExhibitors" type="checkbox">
            {({ input }) => (
              <StyledRadioButton
                text={t('eventsCommon.pages.merchandise.hasExhibitors')}
                textCSS={LABEL_CSS}
                icon={CheckIcon}
                {...input}
              />
            )}
          </Field>

          {hasExhibitors && (
            <>
              <Field
                name="exhibitorNumber"
                type="number"
                pattern="[0-9]*"
                parse={formParsers.toNumber}
                format={formFormatters.numberToString}
                validate={combineValidators(validators.isNegativeNumber, validators.isNumber)}>
                {field => (
                  <StyledInput
                    label={t('eventsCommon.pages.merchandise.form.field.exhibitorNumber.label')}
                    placeholder="0"
                    {...field}
                  />
                )}
              </Field>
              <Description>{t('eventsCommon.pages.merchandise.standType')}</Description>
              <CardsGroup>
                {options.map(({ value, title, icon }) => (
                  <Field key={value} name="standTypes" type="checkbox" value={value}>
                    {({ input }) => (
                      <CardRadioButton
                        iconWrapperCSS={ICON_WRAPPER_CSS}
                        id={`${value}-option`}
                        icon={icon}
                        title={title}
                        {...input}
                      />
                    )}
                  </Field>
                ))}
              </CardsGroup>
            </>
          )}
        </FormWrap>
      )}

      <Hints hints={[hint]} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  &:last-child {
    & > div {
      margin-bottom: 0;
    }
  }
`;

const Description = styled(StepDescription)`
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const CardsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 165px);
  grid-gap: 13px;
  margin-bottom: 16px;

  @media ${DEVICE.tablet} {
    justify-content: flex-start;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 195px);
    margin-bottom: 20px;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  margin-bottom: 20px;

  ${({ checked }) =>
    checked
      ? css`
          margin-bottom: 24px;
        `
      : css`
          margin-bottom: 0;
        `}
`;
