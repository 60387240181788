import { Co2FootprintVO } from 'vo';
import { EventSpaceMeasureUnit } from 'common/types';

const toSquareMeter = (inputValue: number, unit: EventSpaceMeasureUnit) => {
  const mapping: Record<EventSpaceMeasureUnit, (value: number) => number> = {
    m2: value => value,
  };
  const convert = mapping[unit];
  if (!convert) {
    throw new Error(`unknown "${unit}" unit`);
  }
  return convert(inputValue);
};

/**
 * Multipliers taken from the documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */

type Props = {
  eventDurationHours?: number;
  eventVenueSize?: number;
  eventSpaceMeasureUnit?: EventSpaceMeasureUnit;
};

export default (props: Props) => {
  const { eventDurationHours = 0, eventVenueSize = 0, eventSpaceMeasureUnit = EventSpaceMeasureUnit.m2 } = props;

  const eventSpaceSizeM2 = toSquareMeter(eventVenueSize, eventSpaceMeasureUnit);
  const co2FootprintValue = eventDurationHours * 0.0898518579029771 * eventSpaceSizeM2 * 0.31;

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};
