import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { formatWeightValue } from 'utils';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

interface ChartDonutSectionProps {
  co2FootprintPerAttendeVOs: {
    travelAndAccommodation: Co2FootprintVO;
    hospitality: Co2FootprintVO;
    catering: Co2FootprintVO;
    merchandise: Co2FootprintVO;
    eventDurationAndInfo: Co2FootprintVO;
  };
  className?: string;
}

export const ChartDonutSection: FC<ChartDonutSectionProps> = ({ co2FootprintPerAttendeVOs, className }) => {
  const { t } = useTranslation();

  const co2FootprintPerAttendeeVO = Co2FootprintVO.sum(Object.values(co2FootprintPerAttendeVOs));
  const resultPerAttendee = co2FootprintPerAttendeeVO.kg;

  const co2FootprintLevel = useMemo(() => {
    const co2FootprintKg = co2FootprintPerAttendeeVO.kg;
    if (co2FootprintKg <= 300) {
      return 'Low';
    }
    if (co2FootprintKg >= 1500) {
      return 'High';
    }
    return 'Average';
  }, [co2FootprintPerAttendeeVO.kg]);

  const co2footPrintLevelNames = {
    ['Low']: t('eventsCommon.pages.result.footprintLevel.low'),
    ['Average']: t('eventsCommon.pages.result.footprintLevel.average'),
    ['High']: t('eventsCommon.pages.result.footprintLevel.high'),
  };
  const results = [
    {
      markColor: 'var(--chart-color1)',
      text: t('eventsCommon.pages.result.topContainer.marks.travelAndAccommodation'),
      value: co2FootprintPerAttendeVOs.travelAndAccommodation.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color2)',
      text: t('eventsCommon.pages.result.topContainer.marks.hospitality'),
      value: co2FootprintPerAttendeVOs.hospitality.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color3)',
      text: t('eventsCommon.pages.result.topContainer.marks.catering'),
      value: co2FootprintPerAttendeVOs.catering.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color4)',
      text: t('eventsCommon.pages.result.topContainer.marks.merchandise'),
      value: co2FootprintPerAttendeVOs.merchandise.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color5)',
      text: t('eventsCommon.pages.result.topContainer.marks.durationAndVenue'),
      value: co2FootprintPerAttendeVOs.eventDurationAndInfo.stringifiedValue,
    },
  ];
  const renderResults = () => {
    return results.map(({ markColor, text, value }) => (
      <ResultLine key={text}>
        <ResultTitle>
          <Mark $color={markColor} />
          {text}
        </ResultTitle>
        <ResultValue>{value}</ResultValue>
      </ResultLine>
    ));
  };

  return (
    <Root className={className}>
      <StyledChart
        options={{
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
          },
          stroke: { width: 0 },
          colors: [
            'var(--chart-color1)',
            'var(--chart-color2)',
            'var(--chart-color3)',
            'var(--chart-color4)',
            'var(--chart-color5)',
          ],
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (value: number) => {
                return utils.formatWeightValue(value);
              },
            },
          },
          labels: [
            t('eventsCommon.pages.result.topContainer.marks.travelAndAccommodation'),
            t('eventsCommon.pages.result.topContainer.marks.hospitality'),
            t('eventsCommon.pages.result.topContainer.marks.catering'),
            t('eventsCommon.pages.result.topContainer.marks.merchandise'),
            t('eventsCommon.pages.result.topContainer.marks.durationAndVenue'),
          ],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    offsetY: 1,
                    formatter: () => formatWeightValue(resultPerAttendee),
                  },
                  value: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    offsetY: 10,
                    formatter: () => t('eventsCommon.pages.result.topContainer.perAttendee'),
                  },
                  total: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    label: formatWeightValue(resultPerAttendee),
                    formatter: () => t('eventsCommon.pages.result.topContainer.perAttendee'),
                  },
                },
              },
            },
          },
        }}
        series={[
          co2FootprintPerAttendeVOs.travelAndAccommodation.formattedKg,
          co2FootprintPerAttendeVOs.hospitality.formattedKg,
          co2FootprintPerAttendeVOs.catering.formattedKg,
          co2FootprintPerAttendeVOs.merchandise.formattedKg,
          co2FootprintPerAttendeVOs.eventDurationAndInfo.formattedKg,
        ]}
        type="donut"
      />
      <Text>{t('eventsCommon.pages.result.footprintDescription')}</Text>
      <FootprintLevel>{co2footPrintLevelNames[co2FootprintLevel]}</FootprintLevel>
      {renderResults()}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 28px 0 18px;
  border-radius: 20px;
  border: 2px solid var(--color8);
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    min-width: 300px;
    max-width: 340px;
  }
  @media ${DEVICE.laptop} {
    margin: 0;
  }
`;

const Text = styled.div`
  margin-bottom: 16px;
  padding: 0 30px;
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  text-align: center;
`;

const FootprintLevel = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  color: var(--color6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const StyledChart = styled(Chart)`
  position: relative;
  margin-bottom: 17px;

  .apexcharts-datalabel-label {
    ${TYPOGRAPHY.titleSemibold24}
    color: var(--color6);
  }

  .apexcharts-datalabel-value {
    ${TYPOGRAPHY.bodyMedium16}
    fill: var(--color6);
  }
`;
const ResultLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 20px;
  &:last-child {
    margin-bottom: 0;
  }
  color: var(--color6);
`;

const Mark = styled.span<{ $color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
  margin-right: 5px;
`;

const ResultTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--color6);

  ${TYPOGRAPHY.captionRegular12};
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyRegular16};
  }
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.captionRegular12};
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyRegular16};
  }
`;
