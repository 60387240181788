import calculateTravelAndAccomodation from './calculate-travel-and-accommodation';
import calculateHospitality from './calculate-hospitality';
import calculateCatering from './calculate-catering';
import calculateMerchandise from './calculate-merchandise';
import calculateEventDurationAndInfo from './calculate-event-duration-and-info';

const co2FootprintCalculator = {
  calculateTravelAndAccomodation,
  calculateHospitality,
  calculateCatering,
  calculateMerchandise,
  calculateEventDurationAndInfo,
};

export { co2FootprintCalculator };
