import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, LanguageOption } from '@net0-calc/common/types';
import { StorageService } from '@net0-calc/common/services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { FranceFlagIcon, GermanyFlagIcon, ItalyFlagIcon, UnitedStatesFlagIcon } from '@net0-calc/common/assets/svg';
import { LanguageDropdownLayout } from '@net0-calc/common/components';

export const languagesOptions: LanguageOption[] = [
  {
    icon: <UnitedStatesFlagIcon />,
    name: Language.ENGLISH,
  },
  {
    icon: <GermanyFlagIcon />,
    name: Language.DEUTSCH,
  },
  {
    icon: <FranceFlagIcon />,
    name: Language.FRANÇAIS,
  },
  {
    icon: <ItalyFlagIcon />,
    name: Language.ITALIANO,
  },
];

export const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation();
  const language = useAppSelector(selectors.dictionary.selectLanguage);
  const [currentLanguage, setCurrentLanguage] = useState(
    languagesOptions.find(({ name }) => name === language) as LanguageOption,
  );
  const dispatch = useAppDispatch();

  const handleSwitchLanguage = async (item: LanguageOption) => {
    if (item.name === language) {
      return;
    }
    setCurrentLanguage(item);
    await i18n.changeLanguage(item.name);
    dispatch(actions.dictionary.setLanguage(item.name));
    StorageService.setItem('language', item.name);
  };

  return (
    <LanguageDropdownLayout
      currentLanguage={currentLanguage}
      languagesOptions={languagesOptions}
      onSwitchLanguage={handleSwitchLanguage}
    />
  );
};
