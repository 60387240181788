import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useFetchAnswer, useFormValues } from '../MainPage/hooks';
import { Co2FootprintVO } from 'vo';
import { TipsToReduce, TopContainer } from 'pages/MainPage/components/Result/components';
import { MainLayout } from 'layouts';
import { useCo2FootprintPerAttendeVOs } from 'hooks/useCo2FootprintPerAttendeVOs';
import { Co2FootprintsVOs } from 'common/types';
import { Loader } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';

export const ResultsPage = () => {
  const { id } = useParams<{ id: string }>();

  const [answer, { isLoading }] = useFetchAnswer(id ?? null);

  const { formValues, setFormValues } = useFormValues();

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState<Co2FootprintsVOs>({
    travelAndAccommodation: Co2FootprintVO.create(0, 'kg'),
    hospitality: Co2FootprintVO.create(0, 'kg'),
    catering: Co2FootprintVO.create(0, 'kg'),
    merchandise: Co2FootprintVO.create(0, 'kg'),
    eventDurationAndInfo: Co2FootprintVO.create(0, 'kg'),
  });
  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs || {}));
  const co2FootprintPerAttendeVOs = useCo2FootprintPerAttendeVOs(formValues, co2FootprintsVOs);

  useEffect(() => {
    if (answer) {
      const { formValues, co2Footprint } = answer.answers;
      const { cateringKg, eventDurationAndInfoKg, hospitalityKg, merchandiseKg, travelAndAccommodationKg } =
        co2Footprint;

      const unit = 'kg';
      setFormValues(formValues);
      setCo2FootprintsVOs({
        catering: Co2FootprintVO.create(cateringKg, unit),
        hospitality: Co2FootprintVO.create(hospitalityKg, unit),
        merchandise: Co2FootprintVO.create(merchandiseKg, unit),
        eventDurationAndInfo: Co2FootprintVO.create(eventDurationAndInfoKg, unit),
        travelAndAccommodation: Co2FootprintVO.create(travelAndAccommodationKg, unit),
      });
    }
  }, [answer]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  return (
    <MainLayout isBackButtonVisible={false}>
      <Root>
        <TopContainer
          co2FootprintPerAttendeVOs={co2FootprintPerAttendeVOs}
          eventName={formValues.eventName || 'Unknown'}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
        />
        <StyledTipsToReduce answerId={null} />
      </Root>
    </MainLayout>
  );
};
const Root = styled.div`
  width: 100%;
  min-height: 1200px;
  @media ${DEVICE.laptop} {
    padding-bottom: 25px;
  }
`;
const StyledTipsToReduce = styled(TipsToReduce)`
  margin-bottom: 0;
`;
const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;
