import { BoxIcon, CalendarIcon, CutleryIcon, HomeIcon, PeopleIcon, PlaneIcon } from '@net0-calc/common/assets/svg';
import { StepItemProps } from '@net0-calc/common/types';
import { CalculatorFormValues, StepName } from 'common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MerchandisePage,
  HospitalityPage,
  DurationAndVenuePage,
  CateringPage,
  TravelAndAccommodationPage,
  EventSummaryPage,
  WelcomePage,
} from '../components/wizard-form-pages';

export const useSteps = () => {
  const { t } = useTranslation();
  const [currentStepIndex, changeCurrentStepIndex] = useState(0);

  const stepsMap: Record<StepName, StepItemProps<CalculatorFormValues, StepName>> = {
    [StepName.WELCOME]: {
      name: StepName.WELCOME,
      title: '',
      icon: null,
      page: <WelcomePage />,
    },
    [StepName.EVENT_SUMMARY]: {
      name: StepName.EVENT_SUMMARY,
      title: t('eventsCommon.mainPage.steps.eventSummary'),
      icon: <PeopleIcon />,
      page: <EventSummaryPage />,
    },
    [StepName.DURATION_AND_VENUE_INFO]: {
      name: StepName.DURATION_AND_VENUE_INFO,
      title: t('eventsCommon.mainPage.steps.durationAndVenue'),
      icon: <CalendarIcon stroke={'#BCBBBB'} />,
      page: <DurationAndVenuePage />,
    },
    [StepName.TRAVEL_AND_ACCOMMODATION]: {
      name: StepName.TRAVEL_AND_ACCOMMODATION,
      title: t('eventsCommon.mainPage.steps.travelAndAccommodation'),
      icon: <PlaneIcon />,
      page: <TravelAndAccommodationPage />,
    },
    [StepName.HOSPITALITY]: {
      name: StepName.HOSPITALITY,
      title: t('eventsCommon.mainPage.steps.hospitality'),
      icon: <HomeIcon />,
      page: <HospitalityPage />,
    },
    [StepName.CATERING]: {
      name: StepName.CATERING,
      title: t('eventsCommon.mainPage.steps.catering'),
      icon: <CutleryIcon />,
      page: <CateringPage />,
    },
    [StepName.MERCHANDISE]: {
      name: StepName.MERCHANDISE,
      title: t('eventsCommon.mainPage.steps.merchandise'),
      icon: <BoxIcon />,
      page: <MerchandisePage />,
    },
  };

  const stepsNames = [
    StepName.WELCOME,
    StepName.EVENT_SUMMARY,
    StepName.DURATION_AND_VENUE_INFO,
    StepName.TRAVEL_AND_ACCOMMODATION,
    StepName.HOSPITALITY,
    StepName.CATERING,
    StepName.MERCHANDISE,
  ] as StepName[];

  const steps = stepsNames.map(stepName => stepsMap[stepName]);

  const currentStep = steps[currentStepIndex];
  const currentStepName = currentStep.name;
  const isLastStep = currentStepIndex === steps.length - 1;

  return [steps, { changeCurrentStepIndex, currentStepIndex, currentStep, currentStepName, isLastStep }] as const;
};
