import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { StandType } from 'common/types';

/**
 * Multipliers taken from the documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */

type CalculatingDetailedMerchandiseProps = {
  tShirtsCount?: number;
  brandedBagsCount?: number;
  usedPlasticKg?: number;
  usedPaperKg?: number;
  usedWoodKg?: number;
};

const calculateDetailedMerchandise = (props: CalculatingDetailedMerchandiseProps) => {
  const { tShirtsCount = 0, brandedBagsCount = 0, usedPlasticKg = 0, usedPaperKg = 0, usedWoodKg = 0 } = props;

  const co2FootprintValue = utils.sumList([
    tShirtsCount * 2.231,
    brandedBagsCount * 3.1,
    usedPlasticKg * 3.11606,
    usedPaperKg * 0.85,
    usedWoodKg * 0.414,
  ]);

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};

type CalculatingLightMerchandiseProps = {
  hasExhibitors?: boolean;
  exhibitorsNumber?: number;
  standTypes?: StandType[];
  promotionalBagsNumber?: number;
};

const calculateLightMerchandise = (props: CalculatingLightMerchandiseProps) => {
  const { promotionalBagsNumber = 0, hasExhibitors = false, exhibitorsNumber = 0, standTypes = [] } = props;

  const PROMOTIONAL_BAGS_COEFF = 3.1;
  if (!hasExhibitors) {
    const co2FootprintValue = promotionalBagsNumber * PROMOTIONAL_BAGS_COEFF;
    return Co2FootprintVO.create(co2FootprintValue, 'kg');
  }

  const co2FootprintValue =
    promotionalBagsNumber * PROMOTIONAL_BAGS_COEFF +
    exhibitorsNumber *
      utils.sumList([
        standTypes.includes(StandType.BASIC) ? 1.32 : 0,
        standTypes.includes(StandType.REGULAR) ? 2.64 : 0,
        standTypes.includes(StandType.CREATIVE) ? 3.96 : 0,
      ]);

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};

type Props = CalculatingDetailedMerchandiseProps &
  CalculatingLightMerchandiseProps & {
    isMerchandiseDetailed?: boolean;
  };

export default (props: Props) => {
  const { isMerchandiseDetailed } = props;
  if (isMerchandiseDetailed) {
    return calculateDetailedMerchandise(props);
  }
  return calculateLightMerchandise(props);
};
