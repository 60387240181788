import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';

/**
 * Multipliers taken from the documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */

type CalculatingDetailedCateringProps = {
  breakfastsNumber?: number;
  fishOrMeatLunchesNumber?: number;
  vegetarianLunchesNumber?: number;
  meatDinnersNumber?: number;
  fishOrPoultryDinnersNumber?: number;
  vegetarianDinnersNumber?: number;
  coffeeBreaksNumber?: number;
  cocktailsNumber?: number;
};

const calculateDetailedCatering = (props: CalculatingDetailedCateringProps) => {
  const {
    breakfastsNumber = 0,
    fishOrMeatLunchesNumber = 0,
    vegetarianLunchesNumber = 0,
    meatDinnersNumber = 0,
    fishOrPoultryDinnersNumber = 0,
    vegetarianDinnersNumber = 0,
    cocktailsNumber = 0,
    coffeeBreaksNumber = 0,
  } = props;

  const co2FootprintValue = utils.sumList([
    breakfastsNumber * 2.57,
    fishOrMeatLunchesNumber * 2.2,
    vegetarianLunchesNumber * 0.68,
    meatDinnersNumber * 19.8,
    fishOrPoultryDinnersNumber * 4,
    vegetarianDinnersNumber * 2.84,
    cocktailsNumber * 1.3,
    coffeeBreaksNumber * 2.2,
  ]);

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};

type CalculatingLightCateringProps = {
  lightMealNumber?: number;
  seatedMealsNumber?: number;
};

const calculateLightCatering = (props: CalculatingLightCateringProps) => {
  const { lightMealNumber = 0, seatedMealsNumber = 0 } = props;
  const co2FootprintValue = utils.sumList([lightMealNumber * 1.76, seatedMealsNumber * 3.1]);

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};

type Props = CalculatingDetailedCateringProps &
  CalculatingLightCateringProps & {
    isCateringDetailed?: boolean;
  };

export default (props: Props) => {
  const { isCateringDetailed = false } = props;
  if (isCateringDetailed) {
    return calculateDetailedCatering(props);
  }

  return calculateLightCatering(props);
};
