import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues } from 'common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { combineValidators } from 'validators/combineValidators';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { Input, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { DEVICE } from '@net0-calc/common/assets/styles';

const translationPrefix = 'eventsCommon.pages.travelAndAccommodation';

export const TravelAndAccommodationPage: FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  const fields = [
    {
      name: 'livingLocallyPeopleCount',
      label: t(translationPrefix + '.form.field.livingLocallyPeopleCount.label'),
    },
    {
      name: 'travelingWithinRegionPeopleCount',
      label: t(translationPrefix + '.form.field.travelingWithinRegionPeopleCount.label'),
    },
    {
      name: 'travelingWithinCountryPeopleCount',
      label: t(translationPrefix + '.form.field.travelingWithinCountryPeopleCount.label'),
    },
    {
      name: 'travelingFromAnotherCountryPeopleCount',
      label: t(translationPrefix + '.form.field.travelingFromAnotherCountryPeopleCount.label'),
    },
  ];

  return (
    <Root>
      <StepTitle>{t(translationPrefix + '.title')}</StepTitle>
      <StepDescription>{t(translationPrefix + '.subTitle')}</StepDescription>

      <FormWrap>
        {fields.map(({ name, label }) => (
          <Field
            key={name}
            name={name}
            type="number"
            pattern="[0-9]*"
            parse={formParsers.toNumber}
            format={formFormatters.numberToString}
            validate={combineValidators(validators.isNumber, validators.isNegativeNumber, (value: number) =>
              validators.maxValue(value, 1000),
            )}>
            {field => <StyledInput label={label} placeholder="0" {...field} />}
          </Field>
        ))}
      </FormWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  white-space: nowrap;
`;
