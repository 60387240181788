import { CalculatorFormValues } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';
import { Field } from 'react-final-form';
import * as validators from 'validators';
import { RadioButton, StepTitle } from '@net0-calc/common/components/ui';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CheckIcon, ClockIcon } from '@net0-calc/common/assets/svg';
import { WizardPageProps } from '@net0-calc/common/types';

const LABEL_CSS = css`
  ${TYPOGRAPHY.bodyRegular16}
`;

const WelcomePage: React.FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 'attendees',
      label: t('eventsCommon.pages.welcome.form.field.welcome.options.attendees'),
    },
    {
      value: 'catering',
      label: t('eventsCommon.pages.welcome.form.field.welcome.options.catering'),
    },
    {
      value: 'venueSize',
      label: t('eventsCommon.pages.welcome.form.field.welcome.options.venueSize'),
    },
  ];

  return (
    <Root>
      <StepTitle dangerouslySetInnerHTML={{ __html: t('eventsCommon.pages.welcome.title') }}></StepTitle>
      <div>
        <Description>{t('eventsCommon.pages.welcome.eventInfo')}</Description>
        <Description>{t('eventsCommon.pages.welcome.description')}</Description>
      </div>
      <EstimationSection>
        <StyledClockIcon />
        <Text>{t('eventsCommon.pages.welcome.estimation')}</Text>
      </EstimationSection>

      <div>
        {options.map(({ label, value }) => (
          <Field key={value} name="welcome" type="checkbox" value={value} validate={validators.isArrayMinLength(3)}>
            {({ input }) => <RadioButton text={label} textCSS={LABEL_CSS} icon={CheckIcon} {...input} />}
          </Field>
        ))}
      </div>
    </Root>
  );
};

export default WelcomePage;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
    height: auto;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const Text = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
`;

const Description = styled(Text)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 16px;
  }
`;

const EstimationSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @media ${DEVICE.laptop} {
    margin-bottom: 40px;
  }
`;

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 10px;
  stroke: var(--color2);
`;
