import { CalculatorFormValues, Co2FootprintsVOs } from 'common/types';
import { Co2FootprintVO } from 'vo';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useRef } from 'react';
import { CopyResultLinkButton, DownloadSocialImageButton } from './components';
import { gsap } from 'gsap';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

type Props = {
  formValues: CalculatorFormValues;
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  answerId: string | null;
};
export const ActionButtons: FC<Props> = ({ answerId, co2FootprintsVOs, co2FootprintSumVO, formValues }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 700px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <ActionButtonsWrapper ref={containerRef}>
      <ButtonsGroup>
        {answerId && (
          <DownloadSocialImageButton
            {...{
              formValues,
              co2FootprintSumVO,
              co2FootprintsVOs,
            }}
            answerId={answerId}
          />
        )}
        <CopyResultLinkButton answerId={answerId} />
      </ButtonsGroup>
      <ActionsDescription dangerouslySetInnerHTML={{ __html: t('general.downloadOrShare.title') }}></ActionsDescription>
    </ActionButtonsWrapper>
  );
};
const ActionButtonsWrapper = styled.div`
  margin-bottom: 60px;
`;

const ActionsDescription = styled.p`
  ${TYPOGRAPHY.captionRegular14}
  color: var(--color9);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyRegular16}
  }
`;

const ButtonsGroup = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  @media ${DEVICE.laptop} {
    grid-auto-flow: column;
    max-width: 700px;
  }

  & > button {
    padding: 3px 10px;
    width: auto;
    @media ${DEVICE.laptop} {
      width: auto;
      min-height: 70px;
    }
  }
`;
