import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues } from 'common/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { DEVICE } from '@net0-calc/common/assets/styles';

const translationPrefix = 'eventsCommon.pages.eventSummary';

export const EventSummaryPage: FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle>{t(translationPrefix + '.title')}</StepTitle>
      <StepDescription>{t(translationPrefix + '.subTitle')}</StepDescription>

      <FormWrap>
        <Field key="eventName" name="eventName" validate={validators.isRequired}>
          {field => (
            <StyledInput
              label={t(translationPrefix + '.form.field.eventName.label')}
              placeholder={t(translationPrefix + '.form.field.eventName.placeholder')}
              {...field}
            />
          )}
        </Field>
        <Field name="email" validate={validators.isEmail}>
          {field => (
            <StyledInput
              label={t(translationPrefix + '.form.field.email.label')}
              placeholder={t(translationPrefix + '.form.field.email.placeholder')}
              {...field}
            />
          )}
        </Field>
      </FormWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled(Input)`
  white-space: nowrap;
`;
