import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Co2FootprintVO } from 'vo';
import { useTranslation } from 'react-i18next';
import { ChartDonutSection, ChartSection, TotalFootprintValue } from './components';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

gsap.registerPlugin(ScrollTrigger);

interface TopContainerProps {
  co2FootprintSumVO: Co2FootprintVO;
  eventName: string;
  co2FootprintsVOs: {
    travelAndAccommodation: Co2FootprintVO;
    hospitality: Co2FootprintVO;
    catering: Co2FootprintVO;
    merchandise: Co2FootprintVO;
    eventDurationAndInfo: Co2FootprintVO;
  };
  co2FootprintPerAttendeVOs: {
    travelAndAccommodation: Co2FootprintVO;
    hospitality: Co2FootprintVO;
    catering: Co2FootprintVO;
    merchandise: Co2FootprintVO;
    eventDurationAndInfo: Co2FootprintVO;
  };
}

export const TopContainer: FC<TopContainerProps> = ({
  eventName,
  co2FootprintSumVO,
  co2FootprintPerAttendeVOs,
  co2FootprintsVOs,
}) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 600px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0.5,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  const results = [
    {
      markColor: 'var(--chart-color1)',
      text: t('eventsCommon.pages.result.topContainer.marks.travelAndAccommodation'),
      value: co2FootprintsVOs.travelAndAccommodation.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color2)',
      text: t('eventsCommon.pages.result.topContainer.marks.hospitality'),
      value: co2FootprintsVOs.hospitality.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color3)',
      text: t('eventsCommon.pages.result.topContainer.marks.catering'),
      value: co2FootprintsVOs.catering.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color4)',
      text: t('eventsCommon.pages.result.topContainer.marks.merchandise'),
      value: co2FootprintsVOs.merchandise.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color5)',
      text: t('eventsCommon.pages.result.topContainer.marks.durationAndVenue'),
      value: co2FootprintsVOs.eventDurationAndInfo.stringifiedValue,
    },
  ];

  const renderResults = () => {
    return results.map(({ markColor, text, value }) => (
      <ResultLine key={text}>
        <ResultTitle>
          <Mark $color={markColor} />
          {text}
        </ResultTitle>
        <ResultValue>{value}</ResultValue>
      </ResultLine>
    ));
  };

  return (
    <Root>
      <SectionLeft>
        <EventText>
          {t('eventsCommon.pages.result.topContainer.eventName')} {eventName}
        </EventText>
        <Title>
          <span dangerouslySetInnerHTML={{ __html: t('eventsCommon.pages.result.topContainer.title') }}></span>
          <TotalFootprintValue value={co2FootprintSumVO.kg} />
        </Title>
        <MobileDonutSection co2FootprintPerAttendeVOs={co2FootprintPerAttendeVOs} />
        <ChartSection co2FootprintPerAttendeVOs={co2FootprintPerAttendeVOs} />
        <ResultsWrapper ref={containerRef}>
          <Subtitle>{t('eventsCommon.pages.result.topContainer.subTitle')}</Subtitle>
          {renderResults()}
        </ResultsWrapper>
      </SectionLeft>
      <SectionRight>
        <ChartDonutSection co2FootprintPerAttendeVOs={co2FootprintPerAttendeVOs} />
      </SectionRight>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 10px;
  @media ${DEVICE.laptop} {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const EventText = styled.h4`
  ${TYPOGRAPHY.bodyRegular16}
  margin-bottom: 16px;
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold28}
    font-weight: 400;
    margin-bottom: 24px;
  }
`;

const SectionLeft = styled.div`
  flex: 2;
  @media ${DEVICE.laptop} {
    margin-right: 32px;
  }
  @media ${DEVICE.desktop} {
    margin-right: 0;
  }
`;

const SectionRight = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.titleSemibold28}
  flex-direction: column;
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.mobileL} {
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold44}
    margin-bottom: 44px;
  }
`;

const Subtitle = styled.h6`
  ${TYPOGRAPHY.bodySemibold18}

  display: flex;
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const ResultsWrapper = styled.div`
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const ResultLine = styled.div`
  display: flex;
  max-width: 845px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Mark = styled.span<{ $color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background-color: ${({ $color }) => $color};
  margin-right: 12px;
`;

const ResultTitle = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  display: flex;
  align-items: center;

  @media ${DEVICE.laptop} {
    $${TYPOGRAPHY.boydRegular20}
  }
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.boydRegular20}
  }
`;
const MobileDonutSection = styled(ChartDonutSection)`
  margin-bottom: 44px;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;
