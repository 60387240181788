import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';

/**
 * Multipliers taken from the documentation
 * https://docs.google.com/document/d/1bAgsN3rMe_W4nf3ml0-wFAWZQyMfetijMHk6qzWxH-c/edit
 */

type Props = {
  livingLocallyPeopleCount?: number;
  travelingWithinRegionPeopleCount?: number;
  travelingWithinCountryPeopleCount?: number;
  travelingFromAnotherCountryPeopleCount?: number;
};

export default (props: Props) => {
  const {
    livingLocallyPeopleCount = 0,
    travelingWithinRegionPeopleCount = 0,
    travelingWithinCountryPeopleCount = 0,
    travelingFromAnotherCountryPeopleCount = 0,
  } = props;

  const co2FootprintValue = utils.sumList([
    livingLocallyPeopleCount * 1.39468,
    travelingWithinRegionPeopleCount * 83.88,
    travelingWithinCountryPeopleCount * 275.6328,
    travelingFromAnotherCountryPeopleCount * 2089.875,
  ]);

  return Co2FootprintVO.create(co2FootprintValue, 'kg');
};
