import styled, { css, keyframes } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GreenCar,
  GreenEarth,
  GreenHospital,
  RightArrowIcon,
  BoldArrowIcon,
  BadIcon,
} from '@net0-calc/common/assets/svg';
import { copyResultsLink } from 'utils/copy-results-link';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

gsap.registerPlugin(ScrollTrigger);

type Props = {
  answerId: string | null;
};

export const TipsToReduce: FC<Props> = ({ answerId }) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 900px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <Root ref={containerRef}>
      <Title>
        <EmojiContainer>💡</EmojiContainer> {t('eventsCommon.pages.result.tipsToReduce.title')}
      </Title>

      <StepTitleWrap>
        <StyledBadIcon />
        <span>{t('eventsCommon.pages.result.tipsToReduce.tips.involveAttendees.title')}</span>
      </StepTitleWrap>

      <StepSubtitle>
        <span
          dangerouslySetInnerHTML={{
            __html: t('eventsCommon.pages.result.tipsToReduce.tips.involveAttendees.text'),
          }}></span>{' '}
        <ButtonInTextWrapper>
          <ButtonInText
            onClick={() => {
              copyResultsLink(answerId);
            }}>
            <span>{t('eventsCommon.pages.result.tipsToReduce.getResults')}</span>
            <BoldArrowIcon stroke="var(--color2)" />
          </ButtonInText>
        </ButtonInTextWrapper>
      </StepSubtitle>

      <StepTitleWrap>
        <StyledBadIcon />
        <span>{t('eventsCommon.pages.result.tipsToReduce.tips.setOptimalTemperature.title')}</span>
      </StepTitleWrap>
      <StepSubtitle>{t('eventsCommon.pages.result.tipsToReduce.tips.setOptimalTemperature.text')}</StepSubtitle>

      <StepTitleWrap>
        <StyledBadIcon />
        <span>{t('eventsCommon.pages.result.tipsToReduce.tips.manageWasteAndRecycle.title')}</span>
      </StepTitleWrap>
      <StepSubtitle>{t('eventsCommon.pages.result.tipsToReduce.tips.manageWasteAndRecycle.text')}</StepSubtitle>

      <StepTitleWrap>
        <StyledBadIcon />
        <span>{t('eventsCommon.pages.result.tipsToReduce.tips.minimisePrising.title')}</span>
      </StepTitleWrap>
      <StepSubtitle>{t('eventsCommon.pages.result.tipsToReduce.tips.minimisePrising.text')}</StepSubtitle>

      <StepTitleWrap>
        <StyledBadIcon />
        <span>{t('eventsCommon.pages.result.tipsToReduce.tips.workWithLocalSuppliers.title')}</span>
      </StepTitleWrap>
      <StepSubtitle>{t('eventsCommon.pages.result.tipsToReduce.tips.workWithLocalSuppliers.text')}</StepSubtitle>

      <LinksWrapper>
        <Link href={`https://net0.com/blog/ghg-reporting`} target="_blank">
          <StyledGreenEarth />
          <LinkText>{t('eventsCommon.pages.result.tipsToReduce.links.whitepapert')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
        <Link href={`https://net0.com/blog/reduce-carbon-footprint`} target="_blank">
          <StyledGreenCar />
          <LinkText>{t('eventsCommon.pages.result.tipsToReduce.links.guide')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
        <Link href={`https://net0.com/blog/carbon-offset`} target="_blank">
          <StyledGreenHospital />
          <LinkText>{t('eventsCommon.pages.result.tipsToReduce.links.hotels')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
      </LinksWrapper>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 845px;
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
  }
`;

const Title = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  margin-bottom: 20px;
  display: flex;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const LinksWrapper = styled.div`
  max-width: 1117px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    flex-direction: row;
  }
`;

const Link = styled.a`
  ${TYPOGRAPHY.bodyMedium20}
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--color14);
  color: var(--color2);
  text-decoration: none;
  transition: 0.3s;
  width: 343px;

  &:not(:last-child) {
    margin: 0 0 12px 0;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyMedium16}

    width: fit-content;
    padding: 20px;

    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  }

  &:hover {
    border: 2px solid var(--color2);
  }
`;

const LinkText = styled.p`
  flex: 1;
  width: 100%;

  @media ${DEVICE.laptop} {
    white-space: nowrap;
  }
`;

const iconsCommonCSS = css`
  margin-right: 16px;

  path {
    fill: var(--color2);
  }

  circle {
    fill: var(--color2);
  }
`;

const StyledGreenCar = styled(GreenCar)`
  ${iconsCommonCSS}
`;

const StyledGreenHospital = styled(GreenHospital)`
  ${iconsCommonCSS}
`;

const StyledGreenEarth = styled(GreenEarth)`
  ${iconsCommonCSS}
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  margin-left: 16px;
  stroke: var(--color2);
`;

const emojiPulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const EmojiContainer = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  cursor: pointer;
  margin-right: 24px;
  &:hover {
    animation: ${emojiPulseAnimation} 0.2s ease-in normal;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-right: 16px;
  }
`;

const StepTitleWrap = styled.div`
  $${TYPOGRAPHY.boydRegular20}
  color: var(--color6);
  display: flex;
  align-items: center;
  & svg {
    display: block;
    margin-right: 12px;
    flex-shrink: 0;
    width: 26px;
  }

  &:not(:last-child) {
    margin-bottom: 13px;
  }
`;
const StepSubtitle = styled.span`
  ${TYPOGRAPHY.bodyRegular16}
  position: relative;
  display: inline-block;
  padding-left: 38px;
  margin-bottom: 16px;
  color: var(--color9);
  &:nth-last-of-type(1) {
    margin-bottom: 20px;
  }
  @media ${DEVICE.mobileL} {
    margin-bottom: 30px;
    &:nth-last-of-type(1) {
      margin-bottom: 32px;
    }
  }
`;
const ButtonInTextWrapper = styled.span`
  display: inline-block;
`;

const ButtonInText = styled.div`
  ${TYPOGRAPHY.captionMedium12}
  width: auto;
  height: 26px;
  padding: 4px 8px 4px 8px;
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  border-radius: 8px;
  color: var(--color2);
  border: 2px solid var(--color14);
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border: 2px solid var(--color2);
  }
`;

const StyledBadIcon = styled(BadIcon)`
  path {
    stroke: var(--color2);
  }
`;
