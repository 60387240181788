import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" stroke="#D2172B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m13.5 4.5-7 7L3 8" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgCheckIcon;
