import { CalculatorFormValues, EventSpaceMeasureUnit, FoodSource } from 'common/types';
import { useState } from 'react';

const initialFormValues: CalculatorFormValues = {
  eventSpaceMeasureUnit: EventSpaceMeasureUnit.m2,
  welcome: [],
  foodSource: FoodSource.AllFoodIsLocallySorced,

  hasExhibitors: false,
};

export const useFormValues = () => {
  const [formValues, setFormValues] = useState<CalculatorFormValues>(initialFormValues);
  return { initialFormValues, formValues, setFormValues } as const;
};
