import { LoadingStatus } from '@net0-calc/common/types';
import { Answer, api } from 'api';
import { useEffect, useState } from 'react';

export const useFetchAnswer = (answerId: string | null) => {
  const [answer, setAnswer] = useState<Answer | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const doFetch = async () => {
      setLoadingStatus(LoadingStatus.PENDING);
      try {
        if (answerId) {
          const $answer = await api.getAnswerById(answerId).then(response => response.data);
          setAnswer($answer);
        }
        setLoadingStatus(LoadingStatus.FULFILLED);
      } catch (error) {
        console.log(error);

        setLoadingStatus(LoadingStatus.REJECTED);
        setError(error as Error);
      }
    };

    doFetch();
  }, [answerId]);

  const isLoading = loadingStatus === LoadingStatus.PENDING;
  return [answer, { isLoading, loadingStatus, error }] as const;
};
