import { useRef, useState } from 'react';
import { SocialImageLayout } from './SocialImageLayout';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Co2FootprintVO } from 'vo';
import { CalculatorFormValues, Co2FootprintsVOs } from 'common/types';
import { toast } from 'services';
import { api } from 'api';
import { Button } from '@net0-calc/common/components/ui';
import { PngIcon } from '@net0-calc/common/assets/svg';

type Props = {
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  formValues: CalculatorFormValues;
  answerId: string;
};

export const DownloadSocialImageButton = ({ co2FootprintSumVO, co2FootprintsVOs, formValues, answerId }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const imageLayoutRef = useRef<HTMLHtmlElement>(null);

  const downloadImage = async () => {
    const imageNode = imageLayoutRef.current;
    if (!imageNode) {
      console.log('imageRef.current not defined');
      return;
    }
    setIsLoading(true);

    const html = imageLayoutRef.current.outerHTML;

    try {
      const { data } = await api.getResultImage(answerId, html);

      window.open(data, '_blank');
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const buttonTitle = t('general.downloadSocialImageButton.title');
  return (
    <>
      <Button
        variant="secondary"
        icon={<PngIcon stroke="var(--color2)" />}
        type="button"
        onClick={downloadImage}
        title={buttonTitle}
        isLoading={isLoading}
      />
      <ImageLayoutOutsideWrapper>
        <SocialImageLayout rootRef={imageLayoutRef} {...{ formValues, co2FootprintSumVO, co2FootprintsVOs }} />
      </ImageLayoutOutsideWrapper>
    </>
  );
};

const ImageLayoutOutsideWrapper = styled.div`
  position: absolute;
  left: -100000px;
  top: -100000px;
`;
