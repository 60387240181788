import { CalculatorFormValues, StepName } from 'common/types';
import { MainLayout } from 'layouts';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Result, WizardForm } from './components';
import { gsap } from 'gsap';
import { Co2FootprintVO } from 'vo';
import { useSteps, useFormValues, useStepCalculationHandlers, useFetchAnswer } from './hooks';
import * as utils from 'utils';
import React from 'react';
import { Loader } from '@net0-calc/common/components/ui';
import { selectors, useAppSelector } from 'store';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { StepBar } from '@net0-calc/common/components';
import { api } from '@pwc-event/api';
import { useCo2FootprintPerAttendeVOs } from '@pwc-event/hooks/useCo2FootprintPerAttendeVOs';

export const MainPage = () => {
  const isDictionaryLoading = useAppSelector(selectors.dictionary.selectIsDictionaryLoading);
  const [isResultPageSelected, setIsResultPageSelected] = useState(false);

  const { formValues, setFormValues } = useFormValues();
  const { co2FootprintsVOs, handleStepCalculations } = useStepCalculationHandlers({ formValues });
  const [steps, { changeCurrentStepIndex, currentStepIndex, currentStepName, isLastStep }] = useSteps();

  const sharedAnswerId = utils.getFromSearchString('answerId');
  const [answer, { isLoading: isAnswerLoading }] = useFetchAnswer(sharedAnswerId);

  const [answerId, setAnswerId] = useState<string>();

  const co2FootprintPerAttendeVOs = useCo2FootprintPerAttendeVOs(formValues, co2FootprintsVOs);

  useEffect(() => {
    if (answer) {
      setFormValues(answer.answers.formValues);
    }
  }, [answer]);

  const isLoading = isDictionaryLoading || isAnswerLoading;
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  const sendAnswers = () => {
    api
      .sendAnswers({
        answers: {
          locationOrigin: window.location.origin,
          formValues,
          co2Footprint: {
            totalKg: co2FootprintSumVO.kg,
            perPersonKg: Co2FootprintVO.sum(Object.values(co2FootprintPerAttendeVOs)).kg,
            travelAndAccommodationKg: co2FootprintsVOs.travelAndAccommodation.kg,
            hospitalityKg: co2FootprintsVOs.hospitality.kg,
            cateringKg: co2FootprintsVOs.catering.kg,
            merchandiseKg: co2FootprintsVOs.merchandise.kg,
            eventDurationAndInfoKg: co2FootprintsVOs.eventDurationAndInfo.kg,
          },
        },
      })
      .then(({ data }) => {
        setAnswerId(data);
      })
      .catch(console.log);
  };

  const handleStepSubmit = async () => {
    handleStepCalculations(currentStepName);

    if (isLastStep) {
      sendAnswers();
      setIsResultPageSelected(true);
      return;
    }

    gsap
      .fromTo(
        '#step-icon-' + currentStepIndex,
        {
          scaleX: 2.5,
          scaleY: 2.5,
          x: '-10px',
          y: '-10px',
        },
        {
          scaleX: 1,
          scaleY: 1,
          x: '0px',
          y: '0px',
          duration: 0.95,
        },
      )
      .then(() => {
        changeCurrentStepIndex(current => current + 1);
      });
  };

  const handleBackButtonClick = () => {
    if (isResultPageSelected) {
      setIsResultPageSelected(false);
      changeCurrentStepIndex(steps.length - 1);
    } else {
      changeCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs));

  return (
    <MainLayout isBackButtonVisible={currentStepIndex > 0 || isResultPageSelected} onPrevStep={handleBackButtonClick}>
      {isResultPageSelected && (
        <Result
          answerId={answerId}
          formValues={formValues}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
        />
      )}
      {!isResultPageSelected && (
        <PageWrap>
          <StepsContainer>
            <StepBar<CalculatorFormValues, StepName>
              steps={steps}
              currentStepIndex={currentStepIndex}
              onStepClick={changeCurrentStepIndex}
            />
          </StepsContainer>
          <MiddleContainer>
            <WizardForm<CalculatorFormValues>
              initialValues={formValues}
              currentPageIndex={currentStepIndex}
              onStepSubmit={handleStepSubmit}
              co2FootprintSumVO={co2FootprintSumVO}
              onChangeFormSpy={state => setFormValues(state.values)}
              pages={steps.map(({ page }) => page)}
            />
            <PoweredWrapper>
              <PoweredText>Powered by</PoweredText>
              <PoweredLogo src="/logo-gray.png"></PoweredLogo>
            </PoweredWrapper>
          </MiddleContainer>
        </PageWrap>
      )}
    </MainLayout>
  );
};

const PageWrap = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    display: grid;
    justify-content: space-between;
    grid-template-columns: minmax(230px, 326px) minmax(500px, 628px) minmax(120px, 150px);
    grid-template-rows: 1fr;
  }
  @media ${DEVICE.desktop} {
    justify-content: flex-start;
    grid-template-columns: minmax(230px, 326px) minmax(500px, 628px) minmax(120px, 193px);
  }
`;

const StepsContainer = styled.div`
  background-color: var(--color5);
  margin-bottom: 24px;
  z-index: 200;

  @media ${DEVICE.laptop} {
    display: block;
    width: 100%;
    height: 100%;
    min-height: auto;
    margin-bottom: 0;
  }

  @media ${DEVICE.desktop} {
    margin-right: 126px;
  }
`;

const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

const PoweredWrapper = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    align-items: center;
    margin-top: auto;
  }
`;

const PoweredLogo = styled.img`
  width: 77px;
  flex-shrink: 0;
`;

const PoweredText = styled.h5`
  ${TYPOGRAPHY.captionRegular14};
  color: var(--color15);
`;
