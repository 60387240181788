import * as React from 'react';
import { SVGProps } from 'react';

const SvgBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21 16.622V7.378a.759.759 0 0 0-.384-.656l-8.25-4.64a.73.73 0 0 0-.732 0l-8.25 4.64A.76.76 0 0 0 3 7.378v9.244a.76.76 0 0 0 .384.656l8.25 4.64a.73.73 0 0 0 .732 0l8.25-4.64a.76.76 0 0 0 .384-.656v0Z"
      stroke="#BCBBBB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.897 6.994 12.084 12 3.103 6.994M12.084 12 12 22.012"
      stroke="#BCBBBB"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgBoxIcon;
