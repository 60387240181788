import { CalculatorFormValues, Co2FootprintsVOs } from '../common/types';
import * as utils from 'utils';
import { Co2FootprintVO } from 'vo';

export const useCo2FootprintPerAttendeVOs = (formValues: CalculatorFormValues, co2FootprintsVOs: Co2FootprintsVOs) => {
  const attendeesCount = utils.sumList([
    formValues.livingLocallyPeopleCount || 0,
    formValues.travelingWithinRegionPeopleCount || 0,
    formValues.travelingWithinCountryPeopleCount || 0,
    formValues.travelingFromAnotherCountryPeopleCount || 0,
  ]);

  if (attendeesCount === 0) {
    return co2FootprintsVOs;
  }

  const co2FootprintPerAttendeVOs = {
    travelAndAccommodation: Co2FootprintVO.create(co2FootprintsVOs.travelAndAccommodation.kg / attendeesCount, 'kg'),
    hospitality: Co2FootprintVO.create(co2FootprintsVOs.hospitality.kg / attendeesCount, 'kg'),
    catering: Co2FootprintVO.create(co2FootprintsVOs.catering.kg / attendeesCount, 'kg'),
    merchandise: Co2FootprintVO.create(co2FootprintsVOs.merchandise.kg / attendeesCount, 'kg'),
    eventDurationAndInfo: Co2FootprintVO.create(co2FootprintsVOs.eventDurationAndInfo.kg / attendeesCount, 'kg'),
  };

  return co2FootprintPerAttendeVOs;
};
