import { CalculatorFormValues, StepName } from 'common/types';
import { useState } from 'react';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { co2FootprintCalculator } from 'co2-footprint-calculator';

type Params = {
  formValues: CalculatorFormValues;
};

export const useStepCalculationHandlers = ({ formValues }: Params) => {
  /**
   * We rely on that fields of values to be defined in at each step,
   * because this calculation will be execute after filling inputs on step
   */
  const values = formValues as Required<CalculatorFormValues>;
  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState({
    travelAndAccommodation: Co2FootprintVO.create(0, 'kg'),
    hospitality: Co2FootprintVO.create(0, 'kg'),
    catering: Co2FootprintVO.create(0, 'kg'),
    merchandise: Co2FootprintVO.create(0, 'kg'),
    eventDurationAndInfo: Co2FootprintVO.create(0, 'kg'),
  });
  const updateCo2FootprintsVOs = (statePart: Partial<typeof co2FootprintsVOs>) => {
    setCo2FootprintsVOs(currentState => ({
      ...currentState,
      ...statePart,
    }));
  };

  const stepCalculationHandlers: Record<StepName, () => void> = {
    [StepName.WELCOME]: utils.noop,
    [StepName.EVENT_SUMMARY]: utils.noop,
    [StepName.TRAVEL_AND_ACCOMMODATION]: () => {
      const travelAndAccommodation = co2FootprintCalculator.calculateTravelAndAccomodation(values);
      updateCo2FootprintsVOs({ travelAndAccommodation });
    },
    [StepName.DURATION_AND_VENUE_INFO]: () => {
      const eventDurationAndInfo = co2FootprintCalculator.calculateEventDurationAndInfo(values);
      updateCo2FootprintsVOs({ eventDurationAndInfo });
    },
    [StepName.HOSPITALITY]: () => {
      const hospitality = co2FootprintCalculator.calculateHospitality(values);
      updateCo2FootprintsVOs({ hospitality });
    },
    [StepName.CATERING]: () => {
      const catering = co2FootprintCalculator.calculateCatering(values);
      updateCo2FootprintsVOs({ catering });
    },
    [StepName.MERCHANDISE]: () => {
      const merchandise = co2FootprintCalculator.calculateMerchandise(values);
      updateCo2FootprintsVOs({ merchandise });
    },
  };

  const handleStepCalculations = (currentStepName: StepName) => {
    const handle = stepCalculationHandlers[currentStepName];
    handle();
  };

  return { co2FootprintsVOs, handleStepCalculations, updateCo2FootprintsVOs };
};
