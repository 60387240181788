import { Field } from 'react-final-form';
import styled, { css } from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues } from 'common/types';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { combineValidators } from 'validators/combineValidators';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { Input, RadioButton, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

const translationPrefix = 'eventsCommon.pages.hospitality.form.field';

export const HospitalityPage: FC<WizardPageProps<CalculatorFormValues>> = ({ values, form }) => {
  const { t } = useTranslation();

  const percentageOfPeopleInHotelsFields = [
    { name: 'percentageOfPeopleInTwoStarHotel', label: t(translationPrefix + '.twoStartHotel.label') },
    { name: 'percentageOfPeopleInThreeStarHotel', label: t(translationPrefix + '.threeStartHotel.label') },
    { name: 'percentageOfPeopleInFourStarHotel', label: t(translationPrefix + '.fourStartHotel.label') },
    { name: 'percentageOfPeopleInFiveStarHotel', label: t(translationPrefix + '.fiveStartHotel.label') },
  ];

  const knowTheApproximateNumberOfPeople = values?.knowTheApproximateNumberOfPeople;
  const currentPercentSum = knowTheApproximateNumberOfPeople
    ? (values?.percentageOfPeopleInFiveStarHotel || 0) +
      (values?.percentageOfPeopleInFourStarHotel || 0) +
      (values?.percentageOfPeopleInThreeStarHotel || 0) +
      (values?.percentageOfPeopleInTwoStarHotel || 0)
    : 0;

  const validatePercentageSum = (value?: number) => {
    return value && currentPercentSum > 100 ? ' ' : undefined;
  };

  const resetPercentNumberOfPeoples = () => {
    form?.change('percentageOfPeopleInFiveStarHotel', undefined);
    form?.change('percentageOfPeopleInFourStarHotel', undefined);
    form?.change('percentageOfPeopleInThreeStarHotel', undefined);
    form?.change('percentageOfPeopleInTwoStarHotel', undefined);
  };

  useEffect(() => {
    if (!values?.stayingInHotelsPeopleCount && values?.knowTheApproximateNumberOfPeople) {
      form?.change('knowTheApproximateNumberOfPeople', undefined);
      resetPercentNumberOfPeoples();
    }
  }, [values?.knowTheApproximateNumberOfPeople, values?.stayingInHotelsPeopleCount, form]);

  return (
    <Root>
      <StepTitle>{t('eventsCommon.pages.hospitality.title')}</StepTitle>
      <StepDescription>{t('eventsCommon.pages.hospitality.subTitle')}</StepDescription>

      <FormWrap>
        <InputGroup $hasStayingPeople={!!values?.stayingInHotelsPeopleCount}>
          <Field
            name="stayingInHotelsPeopleCount"
            type="number"
            pattern="[0-9]*"
            parse={formParsers.toNumber}
            format={formFormatters.numberToString}
            validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
            {field => (
              <StyledInput
                label={t(translationPrefix + '.numberOfPeopleStayingInHotels.label')}
                placeholder="0"
                {...field}
              />
            )}
          </Field>
          <Field
            name="hotelNightsPerAttendeeCount"
            type="number"
            pattern="[0-9]*"
            parse={formParsers.toNumber}
            format={formFormatters.numberToString}
            validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
            {field => (
              <StyledInput
                label={t(translationPrefix + '.hotelNightsPerAttendeeCount.label')}
                placeholder="0"
                {...field}
              />
            )}
          </Field>
        </InputGroup>

        {!!values?.stayingInHotelsPeopleCount && (
          <Field name="knowTheApproximateNumberOfPeople" type="checkbox">
            {({ input }) => (
              <StyledRadioButton
                text={t(translationPrefix + '.knowTheApproximateNumberOfPeople.label')}
                {...input}
                onChange={value => {
                  resetPercentNumberOfPeoples();
                  input.onChange(value);
                }}
              />
            )}
          </Field>
        )}
        {values?.knowTheApproximateNumberOfPeople && !!values?.stayingInHotelsPeopleCount && (
          <div>
            {percentageOfPeopleInHotelsFields.map(({ name, label }) => {
              return (
                <Field
                  key={name}
                  name={name}
                  label={label}
                  placeholder="0 %"
                  type="number"
                  pattern="[0-9]*"
                  hideError
                  validate={combineValidators(validatePercentageSum, validators.isNegativeNumber)}
                  parse={formParsers.toNumber}
                  format={formFormatters.numberToString}>
                  {field => <StyledInput {...field} />}
                </Field>
              );
            })}
            <NumberNotExceedText $isError={currentPercentSum > 100}>
              {t('general.numberNotExceed100')}
            </NumberNotExceedText>
          </div>
        )}
      </FormWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div<{ $hasStayingPeople: boolean }>`
  ${({ $hasStayingPeople }) =>
    $hasStayingPeople
      ? css`
          margin-bottom: 20px;

          @media ${DEVICE.laptop} {
            margin-bottom: 50px;
          }
        `
      : ''}
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  &:last-child {
    & > div {
      margin-bottom: 0;
    }
  }
`;

const StyledRadioButton = styled(RadioButton)`
  ${({ checked }) =>
    checked
      ? css`
          margin-bottom: 32px;

          @media ${DEVICE.laptop} {
            margin-bottom: 50px;
          }
        `
      : 'margin-bottom: 0;'}

  & > label {
    align-items: flex-start;
  }
`;

const NumberNotExceedText = styled.p<{ $isError?: boolean }>`
  ${TYPOGRAPHY.bodyMedium16}
  color: var(${({ $isError }) => ($isError ? '--color12' : '--color2')});
`;
