import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import * as validators from 'validators';
import { CalculatorFormValues, FoodSource } from 'common/types';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { combineValidators } from 'validators/combineValidators';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { DetailedFormSwitch } from '@net0-calc/common/components';
import { TimeUtil } from '@net0-calc/common/utils';
import { Hints, Input, SelectField, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

export const CateringPage: FC<WizardPageProps<CalculatorFormValues>> = ({ values, form }) => {
  const { t } = useTranslation();

  const detailedFields = [
    {
      name: 'breakfastsNumber',
      label: t('eventsCommon.pages.catering.form.field.breakfastsNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'fishOrMeatLunchesNumber',
      label: t('eventsCommon.pages.catering.form.field.fishOrMeatLunchesNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'vegetarianLunchesNumber',
      label: t('eventsCommon.pages.catering.form.field.vegetarianLunchesNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'meatDinnersNumber',
      label: t('eventsCommon.pages.catering.form.field.meatDinnersNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'fishOrPoultryDinnersNumber',
      label: t('eventsCommon.pages.catering.form.field.fishOrPoultryDinnersNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'vegetarianDinnersNumber',
      label: t('eventsCommon.pages.catering.form.field.vegetarianDinnersNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'coffeeBreaksNumber',
      label: t('eventsCommon.pages.catering.form.field.coffeeBreaksNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'cocktailsNumber',
      label: t('eventsCommon.pages.catering.form.field.cocktailsNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
  ];

  const lightFields = [
    {
      name: 'lightMealNumber',
      label: t('eventsCommon.pages.catering.form.field.lightMealNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
    {
      name: 'seatedMealsNumber',
      label: t('eventsCommon.pages.catering.form.field.seatedMealsNumber.label'),
      placeholder: t('general.placeholder.numberOfMeals'),
    },
  ];

  const foodSourceOptions = [
    {
      label: t('eventsCommon.pages.catering.form.field.foodSource.options.allFood'),
      value: FoodSource.AllFoodIsLocallySorced,
    },
    {
      label: t('eventsCommon.pages.catering.form.field.foodSource.options.someFood'),
      value: FoodSource.SomeFoodIsLocallySorced,
    },
    {
      label: t('eventsCommon.pages.catering.form.field.foodSource.options.noFood'),
      value: FoodSource.NoFoodIsLocallySourced,
    },
  ];

  const isDetailedForm = values?.isCateringDetailed;

  useEffect(() => {
    // const сateringDetailedFieldIsDefined = typeof values?.isCateringDetailed !== 'undefined';
    // if (сateringDetailedFieldIsDefined) {
    //   return;
    // }

    const eventDurationDates = values?.eventDurationDates || [new Date(), new Date()];
    const eventBeginingDate = eventDurationDates[0];
    const mothsDiffBetweenNowAndEventBeginingDate = TimeUtil.getDiffFromNow(eventBeginingDate, 'months');
    const isCateringDetailed = mothsDiffBetweenNowAndEventBeginingDate < 6;
    if (form) {
      form.change('isCateringDetailed', isCateringDetailed);
    }
  }, []);
  const hint = isDetailedForm
    ? t('eventsCommon.pages.catering.hint.advanced')
    : t('eventsCommon.pages.catering.hint.simple');

  return (
    <Root>
      <DetailedFormSwitch name="isCateringDetailed" isDetailedForm={!!isDetailedForm} />
      <StepTitle>{t('eventsCommon.pages.catering.title')}</StepTitle>
      <StepDescription>{t('eventsCommon.pages.catering.subTitle')}</StepDescription>
      {isDetailedForm ? (
        <FormWrap>
          <Field<string> name="foodSource" validate={validators.isRequired}>
            {field => (
              <StyledSelectField
                options={foodSourceOptions}
                label={t('eventsCommon.pages.catering.form.field.foodSource.label')}
                placeholder="0"
                {...field}
              />
            )}
          </Field>
          {detailedFields.map(({ name, label, placeholder }) => (
            <Field
              key={name}
              name={name}
              type="number"
              pattern="[0-9]*"
              parse={formParsers.toNumber}
              format={formFormatters.numberToString}
              validate={combineValidators(validators.isNegativeNumber, validators.isNumber)}>
              {field => <StyledInput label={label} placeholder={placeholder} {...field} />}
            </Field>
          ))}
        </FormWrap>
      ) : (
        <FormWrap>
          {lightFields.map(({ name, label, placeholder }) => (
            <Field
              key={name}
              name={name}
              type="number"
              pattern="[0-9]*"
              parse={formParsers.toNumber}
              format={formFormatters.numberToString}
              validate={combineValidators(validators.isNegativeNumber, validators.isNumber)}>
              {field => <StyledInput label={label} placeholder={placeholder} {...field} />}
            </Field>
          ))}
        </FormWrap>
      )}

      <Hints hints={[hint]} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 60px;
  position: relative;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold36}
    margin-bottom: 50px;
  }
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  &:last-child {
    & > div {
      margin-bottom: 0;
    }
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
