import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { config } from 'config';
import { Language } from '@net0-calc/common/types';
import { StorageService } from '@net0-calc/common/services';
import { actions, store } from 'store';

const DEFAULT_APP_LANGUAGE = Language.ENGLISH;
const supportedLngs = Object.entries(Language).map(([, value]) => value);
const usersLanguage = StorageService.getItem('language');

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: usersLanguage || DEFAULT_APP_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs,
    detection: { lookupLocalStorage: 'language', order: ['localStorage'] },
    backend: {
      loadPath: language => {
        return `${config.DICTIONARY_URL}/dictionary-calculator/${language}`;
      },
      request: async (options, url, payload, callback) => {
        try {
          store.dispatch(actions.dictionary.setIsLoading(true));
          const { data, status } = await axios.get(url);
          callback(null, {
            status,
            data: JSON.stringify(data),
          });
        } catch (error) {
          console.log(error);
        } finally {
          store.dispatch(actions.dictionary.setIsLoading(false));
        }
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
