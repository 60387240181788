import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { copyResultsLink } from 'utils/copy-results-link';
import { Button } from '@net0-calc/common/components/ui';
import { CopyLinkIcon } from '@net0-calc/common/assets/svg';
import styled from 'styled-components';
import { DEVICE } from '@net0-calc/common/assets/styles';

type Props = {
  answerId: string | null;
};
export const CopyResultLinkButton: FC<Props> = ({ answerId }) => {
  const { t } = useTranslation();
  const buttonTitle = t('general.copyResultsLink.title');

  return (
    <StyledButton
      icon={<CopyLinkIcon stroke="var(--color2)" />}
      onClick={() => {
        copyResultsLink(answerId);
      }}
      variant="secondary"
      title={buttonTitle}
      type="button"
    />
  );
};

const StyledButton = styled(Button)`
  @media ${DEVICE.laptop} {
    max-width: 291px;
  }
`;
