import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Bar, Rectangle, Cell, ResponsiveContainer, XAxis, YAxis, BarChart, Tooltip } from 'recharts';
import { PlaneIcon, HomeIcon, CalendarIcon, BoxIcon, CutleryIcon } from '@net0-calc/common/assets/svg';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { DEVICE } from '@net0-calc/common/assets/styles';

const CustomBar = (props: any) => {
  const isMinimum = props.height < 1 && props.height > 0;
  return (
    <Rectangle
      {...props}
      y={isMinimum ? props.y - 4 : props.y}
      height={isMinimum ? 4 : props.height}
      radius={[10, 10, 0, 0]}
    />
  );
};

interface ChartSectionProps {
  className?: string;
  co2FootprintPerAttendeVOs: {
    travelAndAccommodation: Co2FootprintVO;
    hospitality: Co2FootprintVO;
    catering: Co2FootprintVO;
    merchandise: Co2FootprintVO;
    eventDurationAndInfo: Co2FootprintVO;
  };
}

const colors = [
  'var(--chart-color1)',
  'var(--chart-color2)',
  'var(--chart-color3)',
  'var(--chart-color4)',
  'var(--chart-color5)',
];

export const ChartSection: FC<ChartSectionProps> = ({ className, co2FootprintPerAttendeVOs }) => {
  const { t } = useTranslation();

  const data = [
    { name: 'Page A', CO2: co2FootprintPerAttendeVOs.travelAndAccommodation.formattedKg },
    { name: 'Page B', CO2: co2FootprintPerAttendeVOs.hospitality.formattedKg },
    { name: 'Page C', CO2: co2FootprintPerAttendeVOs.catering.formattedKg },
    { name: 'Page D', CO2: co2FootprintPerAttendeVOs.merchandise.formattedKg },
    { name: 'Page E', CO2: co2FootprintPerAttendeVOs.eventDurationAndInfo.formattedKg },
  ];

  const renderCustomAxisTick = ({ x, y, payload }: any) => {
    switch (payload.value) {
      case 'Page A':
        return <PlaneIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page B':
        return <HomeIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page C':
        return <CutleryIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page D':
        return <BoxIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page E':
        return <CalendarIcon stroke={'#BCBBBB'} x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      default:
        return <PlaneIcon />;
    }
  };

  const co2FootprintPerAttendeeVO = Co2FootprintVO.sum(Object.values(co2FootprintPerAttendeVOs));
  const isFootprintZero = Number(co2FootprintPerAttendeeVO.ton) === 0;

  const maxEmissionsSum = data.reduce((acc, curr) => {
    return Math.max(acc, curr.CO2);
  }, 0);

  const barChartLeftPosition = maxEmissionsSum === 0 ? 0 : maxEmissionsSum.toFixed(2).length * 3 + 5;

  return (
    <Root className={className}>
      <ResponsiveContainer>
        <BarChart data={data} maxBarSize={90} margin={{ top: 0, bottom: 60, left: barChartLeftPosition }}>
          <XAxis
            dataKey="name"
            tick={props => renderCustomAxisTick(props)}
            tickLine={false}
            stroke={'var(--color10)'}
            tickMargin={12}
          />
          <YAxis
            tickLine={false}
            tick={{ fontFamily: 'Poppins', fontSize: 12, fill: 'var(--color9)', dx: -10 }}
            domain={[0, isFootprintZero ? 'dataMax + 10' : 'dataMax']}
            tickMargin={12}
            type="number"
            axisLine={{ stroke: 'var(--color10)' }}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            labelStyle={{ fontFamily: 'Poppins', fontSize: 12 }}
            itemStyle={{ fontFamily: 'Poppins', fontSize: 12, textAlign: 'center' }}
            contentStyle={{ borderRadius: 10, background: 'var(--color5)' }}
            formatter={(value: string) => utils.formatWeightValue(Number(value))}
            labelFormatter={label => {
              switch (label) {
                case 'Page A':
                  return t('eventsCommon.pages.result.topContainer.marks.travelAndAccommodation');
                case 'Page B':
                  return t('eventsCommon.pages.result.topContainer.marks.hospitality');
                case 'Page C':
                  return t('eventsCommon.pages.result.topContainer.marks.catering');
                case 'Page D':
                  return t('eventsCommon.pages.result.topContainer.marks.merchandise');
                case 'Page E':
                  return t('eventsCommon.pages.result.topContainer.marks.durationAndVenue');
                default:
                  return '';
              }
            }}
          />
          <Bar dataKey="CO2" shape={<CustomBar />}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Root>
  );
};

export default ChartSection;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  flex: 1;

  @media ${DEVICE.tablet} {
    height: 346px;
    min-width: 325px;
    max-width: 850px;
    border-radius: 20px;
  }

  & .recharts-yAxis .recharts-cartesian-axis-tick:first-child {
    display: none;
  }
`;
