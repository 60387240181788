import * as React from 'react';
import { SVGProps } from 'react';

const SvgBoldArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    stroke="#62C962"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M2.5 6H9.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 2.5L9.5 6L6 9.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgBoldArrowIcon;
